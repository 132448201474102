import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import { debounce } from '../../utils/InputControlUtils';
import { urlWrapper } from '../../utils/urlWrapperUtils';
import Icon from '../Icon';
import NewPopup from '../NewPopup';
import OnLoadWrapper from '../OnLoadWrapper';

import './styles.scss';

/**
 *
 * @visibleName QuickLinks
 */
class QuickLinks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isClient: false,
            modNormal: false,
            showPopup: false,
            layerUrl: '',
            layerTitle: '',
        };

        this.handleScroll = this.handleScroll.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', debounce(this.handleScroll, 100));
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({ isClient: true });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const { isGuide } = this.props;
        const initiated = window.scrollY > 100 && !isGuide;
        this.setState({
            modNormal: initiated,
        });
    }

    closePopup() {
        this.setState({ showPopup: false });
    }

    renderMobile() {
        const { quickLinks } = this.props;
        const className = 'quicklinks';

        return quickLinks.filter(quickLinkItem => quickLinkItem.hideOnMobile !== true).map((quickLinkItem) => {
            const {
                isActive,
                layerUrl,
                options,
            } = quickLinkItem;

            const {
                isBikeChat,
                isTargetBlank,
            } = options || {};

            const tagClasses = [
                `${className}__item`,
                isActive ? `${className}__item--active` : null,
                isBikeChat ? `${className}__item--bike-chat` : null,
            ].filter(Boolean).join(' ');

            return (
                <a
                    key={`ql:${quickLinkItem.label}`}
                    className={tagClasses}
                    href={urlWrapper(quickLinkItem.url)}
                    {...(layerUrl || isTargetBlank ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
                >
                    <Icon icon={quickLinkItem.icon} />
                    <div className={`${className}__text`}>
                        <div>
                            {quickLinkItem.label}
                        </div>
                    </div>
                </a>
            );
        });
    }

    renderDesktop() {
        const { quickLinks } = this.props;
        const className = 'quicklinks';

        return quickLinks.map((quickLinkItem) => {
            const {
                isActive,
                options,
                layerUrl,
            } = quickLinkItem;

            const {
                isBikeChat,
                isTargetBlank,
            } = options || {};

            if (isBikeChat) {
                const {
                    descriptionText,
                } = options || {};

                return (
                    <div className={`${className}__bikechat`}>
                        <a
                            key={`ql:${quickLinkItem.label}`}
                            href={urlWrapper(quickLinkItem.url)}
                        >
                            <div className={`${className}-bikechat`}>
                                <div className={`${className}-bikechat__block ${className}-bikechat__block--icon`}>
                                    <Icon icon={quickLinkItem.icon} />
                                </div>
                                <div
                                    className={
                                        `${className}-bikechat__block ` +
                                        `${className}-bikechat__block--description`
                                    }
                                >
                                    {descriptionText}
                                </div>
                            </div>
                        </a>
                    </div>
                );
            }

            const linkClick = layerUrl ? (e) => {
                e.preventDefault();
                this.setState({
                    showPopup: true,
                    layerUrl: quickLinkItem.layerUrl,
                    layerTitle: quickLinkItem.layerTitle,
                });
            } : undefined;

            const tagClasses = [
                `${className}__item`,
                isActive ? `${className}__item--active` : null,
            ].filter(Boolean).join(' ');

            return (
                <a
                    key={`ql:${quickLinkItem.label}`}
                    className={tagClasses}
                    href={urlWrapper(quickLinkItem.url)}
                    {...(layerUrl ? { onClick: linkClick } : {})}
                    {...(isTargetBlank ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
                >
                    <Icon icon={quickLinkItem.icon} />
                    <div className={`${className}__text`}>
                        <div>
                            {quickLinkItem.label}
                        </div>
                    </div>
                </a>
            );
        }).filter(Boolean);
    }


    render() {
        const {
            isClient,
            layerTitle,
            layerUrl,
            modNormal,
            showPopup,
        } = this.state;

        if (!isClient) { return null; }

        const className = 'quicklinks';
        const { modificators } = this.props;

        let classNames = [className].concat(modificators.map(cname => `${className}--${cname}`));
        if (modNormal) {
            classNames = [].concat(classNames, `${className}--normal`);
        }

        const linkBlockHtml = isMobile ? this.renderMobile() : this.renderDesktop();

        return (
            <div className={classNames.join(' ')}>
                {linkBlockHtml}

                {showPopup &&
                    <NewPopup
                        onClick={this.closePopup}
                        modificators={[]}
                        closeIconModificators={['thick', 'white', 'small']}
                    >
                        <OnLoadWrapper modificators={[]}>
                            <iframe
                                title={layerTitle || undefined}
                                src={layerUrl}
                                height="100%"
                                width="100%"
                                className={`${className}__inner-iframe`}
                            />
                        </OnLoadWrapper>
                    </NewPopup>
                }
            </div>
        );
    }
}

QuickLinks.propTypes = {
    quickLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            url: PropTypes.string,
            icon: PropTypes.string,
            isActive: PropTypes.bool,
            layerUrl: PropTypes.string,
            layerTitle: PropTypes.string,
            hideOnMobile: PropTypes.bool,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    descriptionText: PropTypes.string,
                    isBikeChat: PropTypes.bool,
                    isTargetBlank: PropTypes.bool,
                }),
            ),
        }),
    ),
    isGuide: PropTypes.bool,
    modificators: PropTypes.arrayOf(PropTypes.string),
};

QuickLinks.defaultProps = {
    quickLinks: [],
    isGuide: false,
    modificators: [],
};

export default QuickLinks;
