
const settings = {
    CSS: {
        mq: {
            phone: 599,
            tabletPortrait: 599,
            tabletLandscape: 899,
            desktop: 1139,
            desktopL: 1199,
            desktopXL: 1535,
        },
    },
};

export default settings;
