import React from 'react';
import PropTypes from 'prop-types';
import { extractTranslation } from '@estm/react-utils';
import { languageSwitcherTitles } from '../../constants/languages';

import './styles.scss';
/**
 * @visibleName LanguageSwitcher
 */
class LanguageSwitcher extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: this.props.isExpanded,
        };

        this.onSelect = this.onSelect.bind(this);
    }

    onSelect(item) {
        if (!this.props.isOpenDisable) {
            this.setState({ opened: !this.state.opened });
        }
        this.props.onChange(item);
    }

    render() {
        const { modificators, currentLanguage, isOpenDisable } = this.props;
        const { opened } = this.state;
        const className = 'language-select';
        const classNames = [className].concat(modificators.map(name => `${className}--${name}`));
        const currentLanguageName = extractTranslation(languageSwitcherTitles, currentLanguage);
        const items = languageSwitcherTitles.map(
            item => (// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                    key={item.language}
                    onClick={() => this.onSelect(item.language)}
                >
                    <a href="#top">{item.value}</a>
                </li>),
        );

        return (
            <div className={classNames.join(' ')}>
                {!isOpenDisable &&
                    <div
                        className="language-select__select-custom"
                        onClick={() => this.setState({ opened: !opened })}
                        role="button"
                        tabIndex={-1}
                    >
                        {currentLanguageName}
                    </div>
                }

                <ul className="language-select__select-options">
                    {items}
                </ul>

            </div>
        );
    }
}

LanguageSwitcher.propTypes = {
    isOpenDisable: PropTypes.bool,
    isExpanded: PropTypes.bool,
    onChange: PropTypes.func,
    currentLanguage: PropTypes.string,
    modificators: PropTypes.arrayOf(PropTypes.string),
};

LanguageSwitcher.defaultProps = {
    isOpenDisable: false,
    isExpanded: false,
    onChange: () => {},
    currentLanguage: 'de',
    modificators: [],
};

export default LanguageSwitcher;

