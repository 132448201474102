import React from 'react';
import PropTypes from 'prop-types';

import icons from './RenderType/Styleguidist';
import './RenderType/SSR';

import './styles.scss';

/**
 * @visibleName Icon
 */
const Icon = ({ icon, props }) => {
    const IconComponent = icons[icon];
    let SvgIcon = (
        <svg
            className={icon}
            {...props}
        >
            <use xlinkHref={`#${icon}`} />
        </svg>
    );

    if (process.env.REACT_APP_RENDER_BY === 'styleguidist' && typeof IconComponent !== 'undefined') {
        SvgIcon = (
            <IconComponent
                className={icon}
                {...props}
            >
                <use xlinkHref={`#${icon}`} />
            </IconComponent>
        );
    }

    return (
        <div className={'icon-box'}>
            {SvgIcon}
        </div>
    );
};

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    props: PropTypes.shape({
        shapeRendering: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
    }),
};

Icon.defaultProps = {
    props: {
        shapeRendering: 'auto',
        width: '1em',
        height: '1em',
    },
};

export default Icon;
