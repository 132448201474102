import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { getCookie, setCookie } from '@estm/react-utils';

import { getLanguageByTag, getNavigatorLanguage, getNavigatorLanguageHR } from '../../../utils/browserUtils';

import './styles.scss';

/**
 *
 * @visibleName HeaderTranslation
 */
class HeaderTranslation extends React.Component {
    static getCookieStateName() {
        return 'estm.engadin.header-translation.state';
    }

    static getCookieState() {
        if (typeof window !== 'undefined' && window) {
            return JSON.parse(
                getCookie(HeaderTranslation.getCookieStateName())
                || JSON.stringify(HeaderTranslation.getDefaultCookieState()),
            );
        }

        return HeaderTranslation.getDefaultCookieState();
    }

    static getDefaultCookieState() {
        return {
            isTooltipHidden: false,
        };
    }

    static getDoNotShowTooltipAgainLabelText(languageNameHumanReadable) {
        const doNotShowTooltipAgainLabelForLanguageList = ['German', 'English'];
        return doNotShowTooltipAgainLabelForLanguageList.indexOf(languageNameHumanReadable) !== -1
            ? 'Close'
            : 'Don\'t show again';
    }

    static generateTranslationRedirectLink() {
        const currentLocation = typeof window !== 'undefined' && window ? window.location.href : '';
        const refName = 'header-translation';
        const languageTag = getNavigatorLanguage();
        const glueLocationWithRefSymbol = currentLocation.indexOf('?') !== -1 ? '&' : '?';
        /* eslint-disable-next-line max-len */
        return `https://translate.google.com/translate?js=n&sl=auto&tl=${languageTag}&u=${currentLocation}${glueLocationWithRefSymbol}ref=${refName}`;
    }

    static handleRedirectToTranslate(event) {
        event.preventDefault();
        window.location.href = HeaderTranslation.generateTranslationRedirectLink();
    }

    static isTooltipForCurrentLanguageMustBeHidden(browserRequestLanguageTag) {
        const hideTooltipForLanguageList = ['German', 'English'];
        const languageNameHumanReadable = (typeof navigator !== 'undefined')
            ? getNavigatorLanguageHR()
            : getLanguageByTag(browserRequestLanguageTag);

        return hideTooltipForLanguageList.indexOf(languageNameHumanReadable) !== -1;
    }

    static isTooltipHiddenByConfig(browserRequestLanguageTag) {
        const { isTooltipHidden } = HeaderTranslation.getCookieState();
        return isTooltipHidden
            || HeaderTranslation.isTooltipForCurrentLanguageMustBeHidden(browserRequestLanguageTag);
    }

    constructor(props) {
        super(props);

        const {
            browserRequestLanguageTag,
        } = this.props;

        this.state = {
            isIconClick: false,
            isTooltipVisible: !(HeaderTranslation.isTooltipHiddenByConfig(browserRequestLanguageTag)),
        };
        this.handleDoNotShowTooltipAgain = this.handleDoNotShowTooltipAgain.bind(this);
        this.handleIconClick = this.handleIconClick.bind(this);
        this.handleTooltipVisible = this.handleTooltipVisible.bind(this);
        this.renderTooltipOverlay = this.renderTooltipOverlay.bind(this);
    }

    handleDoNotShowTooltipAgain(event) {
        event.preventDefault();
        setCookie(HeaderTranslation.getCookieStateName(), JSON.stringify({ isTooltipHidden: true }));
        this.setState({
            isIconClick: false,
            isTooltipVisible: false,
        });
    }

    handleIconClick(event) {
        event.preventDefault();
        this.setState({
            isIconClick: true,
            isTooltipVisible: true,
        });
    }

    handleTooltipVisible() {
        const {
            browserRequestLanguageTag,
        } = this.props;
        const {
            isIconClick,
        } = this.state;
        this.setState({
            isTooltipVisible: isIconClick || !(HeaderTranslation.isTooltipHiddenByConfig(browserRequestLanguageTag)),
        });
    }

    renderTooltipOverlay(className) {
        const languageNameHumanReadable = getNavigatorLanguageHR();
        return (
            <div className={`${className}-tooltip-overlay`}>
                {languageNameHumanReadable &&
                <>
                    It looks like your preferred language is {languageNameHumanReadable}.<br />
                </>
                }
                Would you like to translate the page?
                <div className={`${className}-tooltip-overlay__actions`}>
                    <div className={`${className}-tooltip-overlay-action ${className}-tooltip-overlay-action--yes`}>
                        <a onClick={HeaderTranslation.handleRedirectToTranslate} href="/">
                            <span>Yes</span>
                        </a>
                    </div>
                    <div className={`${className}-tooltip-overlay-action`}>
                        <a href="/" onClick={this.handleDoNotShowTooltipAgain}>
                            <span>{
                                HeaderTranslation.getDoNotShowTooltipAgainLabelText(languageNameHumanReadable)
                            }</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            isTooltipVisible,
        } = this.state;

        const className = 'engadin-header-translation';

        return (
            <div className={`${className}`}>
                <Tooltip
                    arrowContent={<div className="rc-tooltip-arrow-inner" />}
                    defaultVisible={false}
                    onVisibleChange={this.handleTooltipVisible}
                    visible={isTooltipVisible}
                    destroyTooltipOnHide={false}
                    id={'translation-redirect-url'}
                    placement={'bottom'}
                    overlay={() => this.renderTooltipOverlay(className)}
                    overlayStyle={{}}
                >
                    <a onClick={this.handleIconClick} href="/">
                        <img
                            src="/images/icon-translation.svg"
                            alt="Translation"
                            title="Would you like to translate the page?"
                        />
                    </a>
                </Tooltip>
            </div>
        );
    }
}

HeaderTranslation.propTypes = {
    browserRequestLanguageTag: PropTypes.string.isRequired,
};

HeaderTranslation.defaultProps = {
    browserRequestLanguageTag: 'de',
};

export default HeaderTranslation;
