import React from 'react';
import LoadScript from 'react-load-script';

class MenuSimpleFormPlaceholder extends React.Component {
    render() {
        return (
            <>
                <div id="app-menu-simple-form" />
                <LoadScript
                    url="/react-booking/static/js/MenuSimpleForm.js"
                    onCreate={() => {
                    }}
                    onError={() => {
                    }}
                    onLoad={() => {
                    }}
                />
            </>
        );
    }
}

MenuSimpleFormPlaceholder.formFields = Object.assign({}, {});

MenuSimpleFormPlaceholder.propTypes = {};

MenuSimpleFormPlaceholder.defaultProps = {};

export default MenuSimpleFormPlaceholder;
