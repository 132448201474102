import React from 'react';
import PropTypes from 'prop-types';
import { urlWrapper } from '../../utils/urlWrapperUtils';

const debounce = (f, delay, context) => {
    let timeout;

    return (...args) => {
        window.clearTimeout(timeout);
        timeout = window.setTimeout(() => f.apply(context, args), delay);
    };
};

export class ImageMap extends React.Component {
    constructor(props) {
        super(props);

        this.image = React.createRef();
        this.onResize = debounce(this.update, this.props.delay, this);
        this.onLoad = debounce(this.update, this.props.delay, this);

        this.state = {
            options: this.props.mapOptions,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        if (this.image.current) {
            this.image.current.addEventListener('load', this.onLoad);
            this.update();
        }
    }

    componentWillUnmount() {
        if (this.onLoad) {
            window.removeEventListener('update', this.onLoad);
        }
        if (this.onResize) {
            window.removeEventListener('resize', this.onResize);
        }
    }


    update() {
        const img = this.image.current;
        const { naturalWidth, naturalHeight, width, height } = img || {};
        this.updateCoords(naturalWidth, naturalHeight, width, height);
    }

    updateCoords(originWidth, originHeight, currentWidth, currentHeight) {
        // prevent `0 || undefined || null` values get in the functions
        if (!originWidth || !originHeight || !currentWidth || !currentHeight) {
            return;
        }
        const isXCoordinate = i => i % 2 === 0;

        const options = this.state.options.map((option, i) => ({
            ...option,
            coords: this.props.mapOptions[i].coords
                .map((coordinate) => {
                    if (isXCoordinate(i)) {
                        return Math.round((currentWidth * coordinate) / originWidth);
                    }

                    return Math.round((currentHeight * coordinate) / originHeight);
                })
                .join(','),
        }));

        this.setState({
            options,
        });
    }

    render() {
        const { imgData } = this.props;
        const mapName = `${this.props.mapName}-${Math.round(Math.random() * 1000)}`;

        return (
            <div>
                <img ref={this.image} useMap={`#${mapName}`} src={imgData.src} alt={imgData.alt} />
                <map name={mapName}>
                    {
                        this.state.options.map(option => (
                            <area
                                href={urlWrapper(option.url)}
                                shape="rect"
                                key={option.title}
                                alt={option.title}
                                title={option.title}
                                coords={option.coords}
                            />
                        ))
                    }
                </map>
            </div>
        );
    }
}

ImageMap.propTypes = {
    mapName: PropTypes.string.isRequired,
    mapOptions: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            title: PropTypes.string,
            coords: PropTypes.arrayOf(PropTypes.number),
        }),
    ).isRequired,

    imgData: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
    }).isRequired,

    delay: PropTypes.number,
};

ImageMap.defaultProps = {
    delay: 500,
};
