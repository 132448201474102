import React from 'react';
import PropTypes from 'prop-types';

import Loader, { LoaderPropTypes } from '../Loader';

class OnLoadWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
        this.elementRef = React.createRef();
    }

    componentDidMount() {
        if (this.elementRef.current && this.elementRef.current.complete) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ loading: false });
        }
    }

    render() {
        return (
            <>
                {this.state.loading
                    ? <Loader {...this.props} />
                    : null
                }
                {React.Children.map(this.props.children, (child) => {
                    const childElement = React.Children.only(child);
                    return React.cloneElement(
                        childElement,
                        {
                            onLoad: (e) => {
                                this.setState({ loading: false });
                                if (typeof this.props.onLoad === 'function') {
                                    this.props.onLoad(e);
                                }
                            },
                            ref: this.elementRef,
                        },
                    );
                })}
            </>
        );
    }
}

OnLoadWrapper.propTypes = {
    onLoad: PropTypes.func,
    ...LoaderPropTypes,
};

OnLoadWrapper.defaultProps = {
    modificators: ['absolute', 'transparent'],
    onLoad: () => {
    },
};


export default OnLoadWrapper;
