import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

class NewPopup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.handleKeypress = this.handleKeypress.bind(this);
    }

    componentDidMount() {
        window.addEventListener('keyup', this.handleKeypress);
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeypress);
    }

    handleKeypress(e) {
        const {
            onClick,
        } = this.props;

        e.preventDefault();
        // "Esc": // IE/Edge specific value
        // "Escape": - other
        if (e.key === 'Esc' || e.key === 'Escape') {
            onClick();
        }
    }

    render() {
        const { onClick, modificators, closeIconModificators } = this.props;

        const className = 'new-popup';
        const classNames = [className].concat(modificators.map(name => `${className}--${name}`));

        const closeIconClass = `${className}__close-icon`;
        const iconClassNames = [closeIconClass].concat(closeIconModificators.map(name => `${closeIconClass}--${name}`));

        return (
            <div className={classNames.join(' ')}>
                <div className={`${className}__window`}>
                    <div className={`${className}__inner`}>
                        {this.props.children}
                    </div>
                </div>
                <div className={`${className}__overlay`} onClick={onClick} role="button" tabIndex="-1">
                    <button className={`${className}__button ${className}__button--close`} onClick={this.props.onClick}>
                        <span className={iconClassNames.join(' ')} />
                    </button>
                </div>

            </div>
        );
    }
}


NewPopup.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    modificators: PropTypes.arrayOf(PropTypes.oneOf(['md', 'lg', 'no-padding', 'customer-login'])),
    closeIconModificators: PropTypes.arrayOf(PropTypes.oneOf(['thick', 'white', 'small'])),
};

NewPopup.defaultProps = {
    modificators: [],
    closeIconModificators: [],
    onClick: () => {},
};

export default NewPopup;
