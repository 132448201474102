import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

import SliderHeroImage from '../Slider';
import { ImageMap } from '../ImageMap';

// eslint-disable-next-line react/prefer-stateless-function
class TownMap extends React.Component {
    render() {
        const { mapImageAlt, townList, className } = this.props;
        const mapData = [
            {
                title: townList.Maloja.title,
                url: townList.Maloja.url,
                coords: [157, 385, 239, 416],
            },
            {
                title: townList.Sils.title,
                url: townList.Sils.url,
                coords: [241, 457, 295, 485],
            },
            {
                title: townList.Silvaplana.title,
                url: townList.Silvaplana.url,
                coords: [368, 437, 490, 465],
            },
            {
                title: townList.StMoritz.title,
                url: townList.StMoritz.url,
                coords: [700, 438, 813, 465],
            },
            {
                title: townList.Pontresina.title,
                url: townList.Pontresina.url,
                coords: [851, 548, 974, 579],
            },
            {
                title: townList.Celerina.title,
                url: townList.Celerina.url,
                coords: [908, 436, 1003, 464],
            },
            {
                title: townList.Samedan.title,
                url: townList.Samedan.url,
                coords: [1044, 401, 1153, 430],
            },
            {
                title: townList.Bever.title,
                url: townList.Bever.url,
                coords: [1193, 413, 1263, 444],
            },
            {
                title: townList.LaPunt.title,
                url: townList.LaPunt.url,
                coords: [1317, 418, 1410, 451],
            },
            {
                title: townList.Madulain.title,
                url: townList.Madulain.url,
                coords: [1425, 438, 1533, 466],
            },
            {
                title: townList.Zuoz.title,
                url: townList.Zuoz.url,
                coords: [1588, 432, 1655, 460],
            },
            {
                title: townList.S_chanf.title,
                url: townList.S_chanf.url,
                coords: [1715, 464, 1807, 495],
            },
        ];
        const sliderMapData = [
            [
                {
                    title: townList.Maloja.title,
                    url: townList.Maloja.url,
                    coords: [154, 381, 240, 418],
                },
                {
                    title: townList.Sils.title,
                    url: townList.Sils.url,
                    coords: [242, 456, 290, 481],
                },
                {
                    title: townList.Silvaplana.title,
                    url: townList.Silvaplana.url,
                    coords: [367, 434, 488, 464],
                },
                {
                    title: townList.StMoritz.title,
                    url: townList.StMoritz.url,
                    coords: [699, 435, 813, 467],
                },
                {
                    title: townList.Pontresina.title,
                    url: townList.Pontresina.url,
                    coords: [855, 547, 975, 580],
                },
                {
                    title: townList.Celerina.title,
                    url: townList.Celerina.url,
                    coords: [905, 434, 1004, 464],
                },
                {
                    title: townList.Samedan.title,
                    url: townList.Samedan.url,
                    coords: [974, 395, 1098, 432],
                },
            ],
            [
                {
                    title: townList.Bever.title,
                    url: townList.Bever.url,
                    coords: [80, 411, 151, 446],
                },
                {
                    title: townList.LaPunt.title,
                    url: townList.LaPunt.url,
                    coords: [203, 418, 297, 450],
                },
                {
                    title: townList.Madulain.title,
                    url: townList.Madulain.url,
                    coords: [310, 433, 419, 467],
                },
                {
                    title: townList.Zuoz.title,
                    url: townList.Zuoz.url,
                    coords: [475, 431, 544, 462],
                },
                {
                    title: townList.S_chanf.title,
                    url: townList.S_chanf.url,
                    coords: [603, 460, 696, 497],
                },
            ],
        ];

        return (
            <div className={className}>
                <div className={`${className}__map`}>
                    <ImageMap
                        mapName="image-map"
                        imgData={{
                            src: '/images/town/map_desktop.jpg',
                            alt: mapImageAlt,
                        }}
                        mapOptions={mapData}
                        delay={500}
                    />
                </div>
                <div className={`${className}__slider`}>
                    <SliderHeroImage>
                        <ImageMap
                            mapName="tablet_map_1"
                            imgData={{
                                src: '/images/town/map_tablet_1.jpg',
                                alt: mapImageAlt,
                            }}
                            mapOptions={sliderMapData[0]}
                            delay={500}
                        />
                        <ImageMap
                            mapName="tablet_map_2"
                            imgData={{
                                src: '/images/town/map_tablet_2.jpg',
                                alt: mapImageAlt,
                            }}
                            mapOptions={sliderMapData[1]}
                            delay={500}
                        />
                    </SliderHeroImage>
                </div>
            </div>
        );
    }
}

const townObject = PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
});

TownMap.propTypes = {
    className: PropTypes.string,
    mapImageAlt: PropTypes.string,
    townList: PropTypes.shape({
        Maloja: townObject,
        Sils: townObject,
        Silvaplana: townObject,
        StMoritz: townObject,
        Pontresina: townObject,
        Celerina: townObject,
        Samedan: townObject,
        Bever: townObject,
        LaPunt: townObject,
        Madulain: townObject,
        Zuoz: townObject,
        S_chanf: townObject,
    }),
};

TownMap.defaultProps = {
    className: 'town-map',
    mapImageAlt: '',
    townList: {
        Maloja: {},
        Sils: {},
        Silvaplana: {},
        StMoritz: {},
        Pontresina: {},
        Celerina: {},
        Samedan: {},
        Bever: {},
        LaPunt: {},
        Madulain: {},
        Zuoz: {},
        S_chanf: {},
    },
};

export default TownMap;
