import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';


const Loader = ({ hidden, modificators, showText, text }) => {
    if (hidden) {
        return null;
    }

    const className = 'loader';
    const classNames = [className].concat(modificators.map(name => `${className}--${name}`));

    return (
        <div className={classNames.join(' ')}>
            <div className={`${className}__overlay`}>
                <div className={`${className}__content`}>
                    <div className={`${className}__spinner`} />
                    {showText
                        ? (
                            <div className={`${className}__text`}>
                                {text || 'Loading...'}
                            </div>
                        )
                        : null
                    }
                </div>
            </div>
        </div>
    );
};

Loader.defaultProps = {
    hidden: false,
    showText: false,
    modificators: [],
};

export const LoaderPropTypes = {
    hidden: PropTypes.bool,
    showText: PropTypes.bool,
    text: PropTypes.string,
    modificators: PropTypes.arrayOf(PropTypes.oneOf(['relative', 'absolute', 'white', 'transparent', 'simple'])),
};

Loader.propTypes = {
    ...LoaderPropTypes,
};

export default Loader;
