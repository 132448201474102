/* eslint-disable import/extensions */
import { ReactComponent as ArrowLeft } from '../../../svg/arrow_left.svg';
import { ReactComponent as ArrowRight } from '../../../svg/arrow_right.svg';
import { ReactComponent as ArrowBack } from '../../../svg/arrow_back.svg';
import { ReactComponent as Search } from '../../../svg/search.svg';
import { ReactComponent as DropdownArrow } from '../../../svg/dropdown_arrow.svg';
import { ReactComponent as Nice } from '../../../svg/weather_type_1.svg';
import { ReactComponent as Cloudy } from '../../../svg/weather_type_2.svg';
import { ReactComponent as Snowing } from '../../../svg/weather_type_3.svg';
import { ReactComponent as Rainy } from '../../../svg/weather_type_4.svg';
import { ReactComponent as Foggy } from '../../../svg/weather_type_5.svg';
import { ReactComponent as Calendar } from '../../../svg/estm_eng_calendar.svg';
import { ReactComponent as ChevronThick } from '../../../svg/estm_eng_chevron_thick.svg';
import { ReactComponent as ChevronThin } from '../../../svg/estm_eng_chevron_thin.svg';
import { ReactComponent as Filter } from '../../../svg/estm_eng_filter.svg';
import { ReactComponent as Guests } from '../../../svg/estm_eng_guests.svg';
import { ReactComponent as Livechat } from '../../../svg/estm_eng_livechat.svg';
import { ReactComponent as LivechatBike } from '../../../svg/estm_eng_livechat_bike.svg';
import { ReactComponent as Mail } from '../../../svg/engadin_eng_mail.svg';
import { ReactComponent as Map } from '../../../svg/estm_eng_map.svg';
import { ReactComponent as Phone } from '../../../svg/engadin_eng_phone.svg';
import { ReactComponent as Playbutton } from '../../../svg/eng_playbutton.svg';
import { ReactComponent as Rooms } from '../../../svg/estm_eng_rooms.svg';
import { ReactComponent as EstmSearch } from '../../../svg/estm_eng_search.svg';
import { ReactComponent as ShareFb } from '../../../svg/estm_eng_share_fb.svg';
import { ReactComponent as ShareMail } from '../../../svg/estm_eng_share_mail.svg';
import { ReactComponent as SharePrint } from '../../../svg/estm_eng_share_print.svg';
import { ReactComponent as ShareTwitter } from '../../../svg/estm_eng_share_twitter.svg';
import { ReactComponent as Slopes } from '../../../svg/estm_eng_slopes.svg';
import { ReactComponent as Timespan } from '../../../svg/estm_eng_timespan.svg';
import { ReactComponent as Web } from '../../../svg/engadin_eng_web.svg';
import { ReactComponent as Webcam } from '../../../svg/estm_eng_webcam.svg';
import { ReactComponent as Youtube } from '../../../svg/engadin_eng_youtube.svg';
import { ReactComponent as Twitter } from '../../../svg/engadin_eng_twitter.svg';
import { ReactComponent as Tripadvisor } from '../../../svg/engadin_eng_tripadvisor.svg';
import { ReactComponent as TouristInfo } from '../../../svg/engadin_eng_tourist_info.svg';
import { ReactComponent as Instagram } from '../../../svg/engadin_eng_instagram.svg';
import { ReactComponent as Facebook } from '../../../svg/engadin_eng_facebook.svg';
import { ReactComponent as DirectionsTo } from '../../../svg/engadin_eng_directions_to.svg';
import { ReactComponent as DirectionsFrom } from '../../../svg/engadin_eng_directions_from.svg';
import { ReactComponent as AppStore } from '../../../svg/app_store.svg';
import { ReactComponent as GooglePlay } from '../../../svg/google_play.svg';
import { ReactComponent as Check } from '../../../svg/estm_eng_checkmark.svg';
import { ReactComponent as ResetBtn } from '../../../svg/reset.svg';
import { ReactComponent as StarLinear } from '../../../svg/star_linear.svg';
import { ReactComponent as StarFilled } from '../../../svg/star_filled.svg';
import { ReactComponent as Bergel } from '../../../svg/stm_booking_bergell.svg';
import { ReactComponent as Bike } from '../../../svg/stm_booking_bike_hotel.svg';
import { ReactComponent as RailAsteriskCable } from '../../../svg/stm_booking_bus_cable_railway_asterisk.svg';
import { ReactComponent as BusInc } from '../../../svg/stm_booking_bus_inc.svg';
import { ReactComponent as BusRail } from '../../../svg/stm_booking_bus_railway_inc.svg';
import { ReactComponent as BusRailAsterisk } from '../../../svg/stm_booking_bus_railway_inc_asterisk.svg';
import { ReactComponent as GolfHotel } from '../../../svg/stm_booking_golfhotel.svg';
import { ReactComponent as SkipassA } from '../../../svg/stm_booking_hotel_skipass_a.svg';
import { ReactComponent as SkipassB } from '../../../svg/stm_booking_hotel_skipass_b.svg';
import { ReactComponent as RailInc } from '../../../svg/stm_booking_railway_inc.svg';
import { ReactComponent as RailAsterisk } from '../../../svg/stm_booking_railway_inc_asterisk.svg';
import { ReactComponent as SleepSki } from '../../../svg/stm_booking_sleep_ski.svg';
import { ReactComponent as Like } from '../../../svg/like.svg';
import { ReactComponent as LikeF } from '../../../svg/like_filled.svg';
import { ReactComponent as Flats } from '../../../svg/estm_eng_flats.svg';
import { ReactComponent as Hotels } from '../../../svg/estm_eng_hotels.svg';
import { ReactComponent as Packages } from '../../../svg/estm_eng_packages.svg';
import { ReactComponent as Sideprint } from '../../../svg/estm_eng_side_print.svg';
import { ReactComponent as Download } from '../../../svg/estm_eng_download.svg';
import { ReactComponent as Close } from '../../../svg/close.svg';
import { ReactComponent as Crown } from '../../../svg/crown.svg';
import { ReactComponent as WeatherDay } from '../../../svg/eng_weather_day.svg';
import { ReactComponent as WeatherDetails } from '../../../svg/estm_eng_weather_details.svg';
import { ReactComponent as Booking } from '../../../svg/estm_eng_booking.svg';
import { ReactComponent as SliderArrow } from '../../../svg/engadin_slider_arrow.svg';
import { ReactComponent as Fax } from '../../../svg/fax.svg';
import { ReactComponent as SnowDeal } from '../../../svg/snow_deal.svg';
import { ReactComponent as Lift1Close } from '../../../svg/lift1_close.svg';
import { ReactComponent as StmClock } from '../../../svg/stm_clock.svg';
import { ReactComponent as StmTimespan } from '../../../svg/stm_timespan.svg';
import { ReactComponent as EstmAvalanches } from '../../../svg/estm_eng_avalanches.svg';
import { ReactComponent as EstmCrosscntry } from '../../../svg/estm_eng_cross_cntry.svg';
import { ReactComponent as EstmLift } from '../../../svg/estm_eng_lift.svg';
import { ReactComponent as EstmSki } from '../../../svg/estm_eng_ski.svg';
import { ReactComponent as EstmSleigh } from '../../../svg/estm_eng_sleigh.svg';
import { ReactComponent as EstmWinterhiking } from '../../../svg/estm_eng_winter_hiking.svg';
import { ReactComponent as EstmEng2chairLift } from '../../../svg/estm_eng_2chair_lift.svg';
import { ReactComponent as EstmEng3chairLift } from '../../../svg/estm_eng_3chair_lift.svg';
import { ReactComponent as EstmEng4chairLift } from '../../../svg/estm_eng_4chair_lift.svg';
import { ReactComponent as EstmEng6chairLift } from '../../../svg/estm_eng_6chair_lift.svg';
import { ReactComponent as EstmEngAerialCableway } from '../../../svg/estm_eng_aerial_cableway.svg';
import { ReactComponent as EstmEngFunicular } from '../../../svg/estm_eng_funicular.svg';
import { ReactComponent as EstmEngGondolaCableway } from '../../../svg/estm_eng_gondola_cableway.svg';
import { ReactComponent as EstmEngMagicCarpet } from '../../../svg/estm_eng_magic_carpet.svg';
import { ReactComponent as EstmEngPonylift } from '../../../svg/estm_eng_ponylift.svg';
import { ReactComponent as EstmEngSkiLift } from '../../../svg/estm_eng_ski_lift.svg';
import { ReactComponent as EstmEngSnowtube } from '../../../svg/estm_eng_snowtube.svg';
import { ReactComponent as Basket } from '../../../svg/basket.svg';
import { ReactComponent as ArrowThin } from '../../../svg/arrow_thin.svg';
import { ReactComponent as SunnySide } from '../../../svg/sunny_side.svg';

export default {
    arrow_left: ArrowLeft,
    arrow_right: ArrowRight,
    arrow_back: ArrowBack,
    search: Search,
    dropdown_arrow: DropdownArrow,
    icon_nice: Nice,
    icon_cloudy: Cloudy,
    icon_snowing: Snowing,
    icon_rainy: Rainy,
    icon_foggy: Foggy,
    estm_eng_calendar: Calendar,
    estm_eng_checkmark: Check,
    estm_eng_chevron_thick: ChevronThick,
    estm_eng_chevron_thin: ChevronThin,
    estm_eng_filter: Filter,
    estm_eng_guests: Guests,
    engadin_eng_mail: Mail,
    engadin_eng_phone: Phone,
    estm_eng_livechat: Livechat,
    estm_eng_livechat_bike: LivechatBike,
    estm_eng_map: Map,
    eng_playbutton: Playbutton,
    estm_eng_rooms: Rooms,
    estm_search: EstmSearch,
    estm_eng_share_fb: ShareFb,
    estm_eng_share_mail: ShareMail,
    estm_eng_share_print: SharePrint,
    estm_eng_share_twitter: ShareTwitter,
    estm_eng_slopes: Slopes,
    estm_eng_timespan: Timespan,
    engadin_eng_web: Web,
    webcam: Webcam,
    engadin_eng_youtube: Youtube,
    engadin_eng_twitter: Twitter,
    engadin_eng_tripadvisor: Tripadvisor,
    engadin_eng_tourist_info: TouristInfo,
    engadin_eng_instagram: Instagram,
    engadin_eng_facebook: Facebook,
    engadin_eng_directions_to: DirectionsTo,
    engadin_eng_directions_from: DirectionsFrom,
    estm_eng_webcam: Webcam,
    app_store: AppStore,
    google_play: GooglePlay,
    reset: ResetBtn,
    star_linear: StarLinear,
    star_filled: StarFilled,
    stm_booking_bergell: Bergel,
    stm_booking_bike_hotel: Bike,
    stm_booking_bus_cable_railway_asterisk: RailAsteriskCable,
    stm_booking_bus_inc: BusInc,
    stm_booking_bus_railway_inc: BusRail,
    stm_booking_bus_railway_inc_asterisk: BusRailAsterisk,
    stm_booking_golfhotel: GolfHotel,
    stm_booking_hotel_skipass_a: SkipassA,
    stm_booking_hotel_skipass_b: SkipassB,
    stm_booking_railway_inc: RailInc,
    stm_booking_railway_inc_asterisk: RailAsterisk,
    stm_booking_sleep_ski: SleepSki,
    like: Like,
    like_filled: LikeF,
    estm_eng_flats: Flats,
    estm_eng_hotels: Hotels,
    estm_eng_packages: Packages,
    estm_eng_download: Download,
    estm_eng_side_print: Sideprint,
    close: Close,
    crown: Crown,
    eng_weather_day: WeatherDay,
    estm_eng_weather_details: WeatherDetails,
    estm_eng_booking: Booking,
    engadin_slider_arrow: SliderArrow,
    fax: Fax,
    snow_deal: SnowDeal,
    lift1_close: Lift1Close,
    stm_clock: StmClock,
    stm_timespan: StmTimespan,
    estm_eng_avalanches: EstmAvalanches,
    estm_eng_cross_cntry: EstmCrosscntry,
    estm_eng_lift: EstmLift,
    estm_eng_ski: EstmSki,
    estm_eng_sleigh: EstmSleigh,
    estm_eng_winter_hiking: EstmWinterhiking,
    estm_eng_2chair_lift: EstmEng2chairLift,
    estm_eng_3chair_lift: EstmEng3chairLift,
    estm_eng_4chair_lift: EstmEng4chairLift,
    estm_eng_6chair_lift: EstmEng6chairLift,
    estm_eng_aerial_cableway: EstmEngAerialCableway,
    estm_eng_funicular: EstmEngFunicular,
    estm_eng_gondola_cableway: EstmEngGondolaCableway,
    estm_eng_magic_carpet: EstmEngMagicCarpet,
    estm_eng_ponylift: EstmEngPonylift,
    estm_eng_ski_lift: EstmEngSkiLift,
    estm_eng_snowtube: EstmEngSnowtube,
    basket: Basket,
    arrow_thin: ArrowThin,
    sunny_side: SunnySide,
};
