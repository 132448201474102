import React from 'react';
import PropTypes from 'prop-types';

import { urlWrapper } from '../../utils/urlWrapperUtils';
import HeaderSearch from './HeaderSearch';
import HeaderTabControl from './HeaderTabControl';
import HeaderTranslation from './HeaderTranslation';
import LanguageSwitcher from '../LanguageSwitcher';
import QuickLinks from '../QuickLinks';

import './styles.scss';

/**
 *
 * @visibleName Header
 */
class Header extends React.Component {
    static isHeaderTranslationDisabledByLanguage(browserRequestLanguageTag) {
        const disableForLanguageList = ['de', 'en'];
        return disableForLanguageList.indexOf(browserRequestLanguageTag) !== -1;
    }

    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
            isSearchPopupOpen: false,
            sticky: false,
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const scrollPosition = window.scrollY || document.documentElement.scrollTop;
        const collapsed = scrollPosition > 250;
        this.setState({
            sticky: collapsed,
        });
    }

    toggleMenu() {
        const updatedIsMenuOpen = !this.state.isMenuOpen;
        const updatedIsSearchPopupOpen = updatedIsMenuOpen ? this.state.isSearchPopupOpen : false;
        this.setState({
            isMenuOpen: updatedIsMenuOpen,
            isSearchPopupOpen: updatedIsSearchPopupOpen,
        });

        const bodyToggleClassName = 'engadin-header--body-block';
        const isHeaderChecked = updatedIsMenuOpen || updatedIsSearchPopupOpen;
        if (isHeaderChecked) {
            document.querySelector('body').classList.add(bodyToggleClassName);
            document.querySelector('html').classList.add(bodyToggleClassName);
        } else {
            document.querySelector('body').classList.remove(bodyToggleClassName);
            document.querySelector('html').classList.remove(bodyToggleClassName);
        }
    }

    render() {
        const {
            currentLanguage,
            footerLinks,
            isCurrentPageSearch,
            languageControl,
            //
            modificators,
            //
            browserRequestLanguageTag,
            ...otherProps
        } = this.props;
        const { isMenuOpen } = this.state;
        const className = 'engadin-header';

        let classNames = [className].concat(modificators.map(name => `${className}--${name}`));
        if (this.state.sticky) {
            classNames = [].concat(classNames, `${className}--is-sticky`);
        }

        const isHeaderChecked = isMenuOpen;
        if (isHeaderChecked) {
            classNames = [].concat(classNames, 'engadin-header--status-open');
        }

        return (
            <div className={classNames.join(' ')}>
                <input
                    id="menu__checkbox"
                    className="menu__checkbox"
                    type="checkbox"
                    checked={isHeaderChecked}
                    onChange={this.toggleMenu}
                />
                <div className={`${className}__overlay`} />
                <div className={`${className}__top`}>
                    <div className={`${className}__container`}>
                        <div className={`${className}__hamburger`}>
                            <label htmlFor="menu__checkbox" className="hamburger">
                                <div className="hamburger-box">
                                    <div className="hamburger-inner" />
                                </div>
                            </label>
                        </div>
                        <div className={`${className}__menu-wrapper`}>
                            <ul className={`${className}__menu`}>
                                {otherProps.tabs.map(tab => (
                                    <li
                                        key={`menu-top:${tab.label}:${tab.url}`}
                                        className={tab.isActive
                                            ? `${className}__menu-item ${className}__menu-item--active`
                                            : `${className}__menu-item`}
                                    >
                                        <a href={urlWrapper(tab.url)}>{tab.label}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className={`${className}__search-wrapper`}>
                            <HeaderSearch
                                searchLinkLabel={otherProps.searchLinkLabel}
                                searchLinkUrl={urlWrapper(otherProps.searchLinkUrl)}
                            />
                        </div>
                        {!Header.isHeaderTranslationDisabledByLanguage(browserRequestLanguageTag) &&
                        <div className={`${className}__logo ${className}__logo--translation`}>
                            <HeaderTranslation
                                browserRequestLanguageTag={browserRequestLanguageTag}
                            />
                        </div>
                        }
                        <div className={`${className}__logo`}>
                            <a href={`/${currentLanguage}/`}>
                                <img
                                    src="/images/engadin_logo.svg"
                                    alt="Logo engadin.ch"
                                    title="Engadin - Ein Tal zum verlieben"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="menu">

                    <div className="menu__tabs">
                        <HeaderTabControl {...otherProps} tabs={otherProps.tabs} />
                    </div>
                    <div className="menu__quicklinks">
                        <QuickLinks
                            quickLinks={otherProps.quickLinks}
                            modificators={['is-menu']}
                        />
                    </div>
                    <div className="menu__language">
                        {
                            languageControl
                                // eslint-disable-next-line
                                ? <div dangerouslySetInnerHTML={languageControl} />
                                : (
                                    <LanguageSwitcher
                                        modificators={['open-top']}
                                        currentLanguage={currentLanguage}
                                        onChange={(language) => {
                                            window.location = `/${language}/`;
                                        }}
                                    />
                                )
                        }
                    </div>
                    <div className="menu__footerlinks">
                        {footerLinks.map(link => (
                            <a
                                key={`footer-link:${link.label}:${link.url}`}
                                href={link.url}
                                {...(link.isTargetBlank ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
                            >
                                {link.label}
                            </a>
                        ))}
                    </div>
                </div>

                <div className={`${className}__quicklinks`}>
                    <QuickLinks
                        quickLinks={otherProps.quickLinks}
                    />
                </div>
            </div>
        );
    }
}

Header.propTypes = {
    modificators: PropTypes.arrayOf(PropTypes.string),
    //
    browserRequestLanguageTag: PropTypes.string.isRequired,
    currentLanguage: PropTypes.string,
    footerLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            isTargetBlank: PropTypes.bool,
            url: PropTypes.string,
            alt: PropTypes.string,
            title: PropTypes.string,
        }),
    ),
    isCurrentPageSearch: PropTypes.bool,
    languageControl: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ __html: PropTypes.string })]),
};

Header.defaultProps = {
    modificators: [],
    //
    browserRequestLanguageTag: 'de',
    currentLanguage: 'de',
    footerLinks: [],
    isCurrentPageSearch: false,
    languageControl: '',
    tabs: [],
};

export default Header;
