import React from 'react';
import PropTypes from 'prop-types';

import { ESTM_LANGUAGE_DE } from '../../../constants/languages';
import Icon from '../../Icon';
import MenuSimpleFormPlaceholder from '../MenuSimpleFormPlaceholder';
import TownMap from '../../TownMap';

import './styles.scss';

/**
 *
 * @visibleName HeaderTabOne
 */
class HeaderTabControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggledTabIndex: 0,
        };
        this.onClickToLabel = this.onClickToLabel.bind(this);
    }

    componentDidMount() {
        window.onresize = () => {
            if (window.innerWidth >= 900) {
                if (this.state.toggledTabIndex === null) {
                    this.setState({
                        toggledTabIndex: 0,
                    });
                }
            }
        };
    }

    onClickToLabel(itemNum) {
        const toggledTabIndex = (this.state.toggledTabIndex === itemNum) ? -1 : itemNum;
        this.setState({ toggledTabIndex });
    }

    render() {
        const addBookingOnTabIndex = null; // `null` value is disable
        const addMapOnTabIndex = 2;
        const className = 'tab-menu';
        const { toggledTabIndex } = this.state;
        const { language, tabs, ...otherProps } = this.props;

        /* eslint-disable max-len */
        return (
            <div className={className}>
                <div className="container">
                    {tabs.map((tab, index) => (
                        <React.Fragment key={`menu-htc-frg:${index + 0}`}>
                            <input
                                type="radio"
                                name="tabs"
                                className="tab-menu__input"
                                id={`toggletab${index}`}
                                readOnly
                                checked={toggledTabIndex === index}
                            />
                            <label
                                className="tab-menu__label"
                                htmlFor={`toggletab${index}`}
                                onClick={() => {
                                    const { isStaticHref } = tab;
                                    if (isStaticHref) {
                                        window.location.href = tab.url;
                                        return false;
                                    }
                                    this.onClickToLabel(index);
                                    return false;
                                }}
                                role="presentation"
                            >
                                <span>
                                    <Icon icon="estm_eng_chevron_thick" />
                                </span>
                                {tab.label}
                            </label>
                            <a href={tab.url} className="tab-menu__label-link">
                                {tab.label}
                            </a>
                            <div id={`tab${index}`} className="tab-menu__tab-content">
                                <div className={`tab${index} header-tab`}>
                                    {
                                        tab.columns.map((column, columnIndex) => (
                                            <div
                                                /* eslint-disable-next-line react/no-array-index-key */
                                                key={`menu-tabhtc:${column.label}:${columnIndex}`}
                                                className={`tab${index}__column`}
                                            >
                                                <div className="sub-navigation">
                                                    <input
                                                        type="checkbox"
                                                        className="sub-navigation__input"
                                                        id={`check${columnIndex}`}
                                                    />
                                                    {column.map(tabItem => (
                                                        <React.Fragment
                                                            key={`menu-tabhtc-col:${tabItem.label}`}
                                                        >
                                                            <div className="sub-navigation__title">
                                                                <a href={tabItem.url}>{tabItem.label}</a>
                                                                {
                                                                    (tabItem.items.length > 0) && (
                                                                        <label
                                                                            className="sub-navigation__label"
                                                                            htmlFor={`check${columnIndex}`}
                                                                        >
                                                                            <Icon icon="estm_eng_chevron_thick" />
                                                                        </label>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className="sub-navigation__list">
                                                                <ul className="engadin-link-list">
                                                                    {tabItem.items.map(tabSubItem => (
                                                                        <li
                                                                            key={`menu-htc-ell:${tabSubItem.label}:${tabSubItem.url}`}
                                                                            className={tabSubItem.label.indexOf('>') === 0
                                                                                ? 'engadin-link-list__more' : null}
                                                                        >
                                                                            <a href={tabSubItem.url}>
                                                                                {tabSubItem.label.replace(/^>/, '')}
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {index === addBookingOnTabIndex ? <MenuSimpleFormPlaceholder /> : null}
                                    {index === addMapOnTabIndex && toggledTabIndex === index ? (
                                        <div className="tab-menu__map">
                                            <TownMap
                                                townList={otherProps.townList}
                                                mapImageAlt=""
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );
    }
}


HeaderTabControl.formFields = Object.assign({}, {});

HeaderTabControl.propTypes = {
    language: PropTypes.string,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            url: PropTypes.string,
            isStaticHref: PropTypes.bool,
            isActive: PropTypes.bool,
            columns: PropTypes.arrayOf(
                PropTypes.arrayOf(
                    PropTypes.shape({
                        label: PropTypes.string,
                        url: PropTypes.string,
                        isActive: PropTypes.bool,
                        items: PropTypes.arrayOf(
                            PropTypes.shape({
                                label: PropTypes.string,
                                url: PropTypes.string,
                                isActive: PropTypes.bool,
                            }),
                        ),
                    }),
                ),
            ),
        }),
    ),
};

HeaderTabControl.defaultProps = {
    language: ESTM_LANGUAGE_DE,
    tabs: [],
};

export default HeaderTabControl;
