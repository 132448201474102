import tags from 'language-tags';

import { defaultSeason } from '../constants/seasons';
import settings from '../constants/settings';

function getWindowRouteState() {
    const defaultState = {
        language: 'de',
        season: defaultSeason,
    };

    if (typeof window !== 'undefined') {
        const pathParts = window.location.pathname.split('/');
        return Object.assign({}, defaultState, pathParts.length ? {
            /* eslint-disable-next-line dot-notation */
            language: pathParts[1] || defaultState['language'],
        } : {});
    }

    return defaultState;
}

function getWindowWidth() {
    return window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth
        || 0;
}

function getWindowHeight() {
    return window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight
        || 0;
}

function widthFitMobile() {
    return getWindowWidth() <= settings.CSS.mq.phone;
}

/**
 * Like React's dangerouslySetInnerHTML, but also with JS evaluation.
 * Usage:
 *   <div ref={setDangerousHtml.bind(null, html)}/>
 */
function setDangerousHtmlAsElementChildren(html, elementId) {
    if (elementId === null) return;
    const targetElement = document.getElementById(elementId);
    const range = document.createRange();
    range.selectNode(targetElement);
    targetElement.replaceChildren(range.createContextualFragment(html));
}

function appendScriptToElement(element, srcUrl, isAsync = false) {
    const script = document.createElement('script');
    script.src = srcUrl;
    script.async = isAsync;
    /* eslint-disable-next-line no-param-reassign */
    element.innerHTML = '';
    element.appendChild(script);
}

/**
 * Returns language tag like: ru-Ru, en-US
 * @returns {string|string}
 */
function getNavigatorLanguage() {
    return navigator.language || (navigator.languages ? navigator.languages[0] : 'en');
}

/**
 * Retrieve language information by tag
 * @returns {string|string}
 */
function getLanguageByTag(tag) {
    const languageTag = tags(tag);
    const tagLanguageDescriptions = languageTag && languageTag.language() ? languageTag.language().descriptions() : [];

    return tagLanguageDescriptions.length > 0 ? tagLanguageDescriptions[0] : undefined;
}

/**
 * Returns human readable language name like: Russian, English
 * @returns {string|string}
 */
function getNavigatorLanguageHR() {
    return getLanguageByTag(getNavigatorLanguage());
}

export {
    appendScriptToElement,
    getLanguageByTag,
    getNavigatorLanguage,
    getNavigatorLanguageHR,
    getWindowRouteState,
    getWindowWidth,
    getWindowHeight,
    setDangerousHtmlAsElementChildren,
    widthFitMobile,
    settings,
};
