require('../../../svg/arrow_left.svg');
require('../../../svg/arrow_right.svg');
require('../../../svg/arrow_back.svg');
require('../../../svg/search.svg');
require('../../../svg/dropdown_arrow.svg');
require('../../../svg/weather_type_1.svg');
require('../../../svg/weather_type_2.svg');
require('../../../svg/weather_type_3.svg');
require('../../../svg/weather_type_4.svg');
require('../../../svg/weather_type_5.svg');
require('../../../svg/estm_eng_calendar.svg');
require('../../../svg/estm_eng_chevron_thick.svg');
require('../../../svg/estm_eng_chevron_thin.svg');
require('../../../svg/estm_eng_filter.svg');
require('../../../svg/estm_eng_guests.svg');
require('../../../svg/estm_eng_livechat.svg');
require('../../../svg/estm_eng_livechat_bike.svg');
require('../../../svg/engadin_eng_mail.svg');
require('../../../svg/estm_eng_map.svg');
require('../../../svg/engadin_eng_phone.svg');
require('../../../svg/eng_playbutton.svg');
require('../../../svg/estm_eng_rooms.svg');
require('../../../svg/estm_eng_search.svg');
require('../../../svg/estm_eng_share_fb.svg');
require('../../../svg/estm_eng_share_mail.svg');
require('../../../svg/estm_eng_share_print.svg');
require('../../../svg/estm_eng_share_twitter.svg');
require('../../../svg/estm_eng_slopes.svg');
require('../../../svg/estm_eng_timespan.svg');
require('../../../svg/engadin_eng_web.svg');
require('../../../svg/estm_eng_webcam.svg');
require('../../../svg/engadin_eng_directions_from.svg');
require('../../../svg/engadin_eng_directions_to.svg');
require('../../../svg/engadin_eng_facebook.svg');
require('../../../svg/engadin_eng_instagram.svg');
require('../../../svg/engadin_eng_tourist_info.svg');
require('../../../svg/engadin_eng_tripadvisor.svg');
require('../../../svg/engadin_eng_twitter.svg');
require('../../../svg/engadin_eng_youtube.svg');
require('../../../svg/app_store.svg');
require('../../../svg/google_play.svg');
require('../../../svg/estm_eng_checkmark.svg');
require('../../../svg/reset.svg');
require('../../../svg/star_linear.svg');
require('../../../svg/star_filled.svg');
require('../../../svg/stm_booking_bergell.svg');
require('../../../svg/stm_booking_bike_hotel.svg');
require('../../../svg/stm_booking_bus_cable_railway_asterisk.svg');
require('../../../svg/stm_booking_bus_inc.svg');
require('../../../svg/stm_booking_bus_railway_inc.svg');
require('../../../svg/stm_booking_bus_railway_inc_asterisk.svg');
require('../../../svg/stm_booking_golfhotel.svg');
require('../../../svg/stm_booking_hotel_skipass_a.svg');
require('../../../svg/stm_booking_hotel_skipass_b.svg');
require('../../../svg/stm_booking_railway_inc.svg');
require('../../../svg/stm_booking_railway_inc_asterisk.svg');
require('../../../svg/stm_booking_sleep_ski.svg');
require('../../../svg/like.svg');
require('../../../svg/like_filled.svg');
require('../../../svg/estm_eng_flats.svg');
require('../../../svg/estm_eng_hotels.svg');
require('../../../svg/estm_eng_packages.svg');
require('../../../svg/estm_eng_download.svg');
require('../../../svg/estm_eng_side_print.svg');
require('../../../svg/close.svg');
require('../../../svg/crown.svg');
require('../../../svg/eng_weather_day.svg');
require('../../../svg/estm_eng_weather_details.svg');
require('../../../svg/estm_eng_booking.svg');
require('../../../svg/engadin_slider_arrow.svg');
require('../../../svg/fax.svg');
require('../../../svg/snow_deal.svg');
require('../../../svg/lift1_close.svg');
require('../../../svg/stm_clock.svg');
require('../../../svg/stm_timespan.svg');
require('../../../svg/estm_eng_avalanches.svg');
require('../../../svg/estm_eng_cross_cntry.svg');
require('../../../svg/estm_eng_lift.svg');
require('../../../svg/estm_eng_ski.svg');
require('../../../svg/estm_eng_sleigh.svg');
require('../../../svg/estm_eng_winter_hiking.svg');

require('../../../svg/estm_eng_2chair_lift.svg');
require('../../../svg/estm_eng_3chair_lift.svg');
require('../../../svg/estm_eng_4chair_lift.svg');
require('../../../svg/estm_eng_6chair_lift.svg');
require('../../../svg/estm_eng_aerial_cableway.svg');
require('../../../svg/estm_eng_funicular.svg');
require('../../../svg/estm_eng_gondola_cableway.svg');
require('../../../svg/estm_eng_magic_carpet.svg');
require('../../../svg/estm_eng_ponylift.svg');
require('../../../svg/estm_eng_ski_lift.svg');
require('../../../svg/estm_eng_snowtube.svg');
require('../../../svg/basket.svg');
require('../../../svg/arrow_thin.svg');
require('../../../svg/sunny_side.svg');
