import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Icon';

import './styles.scss';

/**
 *
 * @visibleName HeaderSearch
 */
class HeaderSearch extends React.Component {
    render() {
        const { searchLinkLabel, searchLinkUrl, modificators } = this.props;
        const className = 'header-search';
        const classNames = [className].concat(modificators.map(name => `${className}--${name}`));
        /* eslint-disable  jsx-a11y/no-static-element-interactions */
        return (
            <form action="#" className={classNames.join(' ')}>
                <div className={`${className}__wrapper`}>
                    <span className={`${className}__search-btn-mobile`} onClick={this.props.onMobileIconClick}>
                        <a href={searchLinkUrl}>
                            <Icon icon="search" />
                        </a>
                    </span>
                    <span className={`${className}__search-btn`} onClick={this.props.onIconClick}>
                        <a href={searchLinkUrl}>
                            <Icon icon="search" />
                        </a>
                    </span>
                    <a className={'underlined-link'} href={searchLinkUrl}>{searchLinkLabel}</a>
                </div>
            </form>
        );
    }
}

HeaderSearch.propTypes = {
    modificators: PropTypes.arrayOf(PropTypes.string),
    searchLinkUrl: PropTypes.string,
    searchLinkLabel: PropTypes.string,
    onIconClick: PropTypes.func,
    onMobileIconClick: PropTypes.func,
};

HeaderSearch.defaultProps = {
    modificators: [],
    searchLinkUrl: '/en/search/',
    searchLinkLabel: 'Search',
    onIconClick: () => {
    },
    onMobileIconClick: () => {
    },
};

export default HeaderSearch;
